@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --sn-site-header: 52px;
    --sn-shell-padding: 8px;
  }
  html {
    -webkit-tap-highlight-color: transparent;
    font-variant-numeric: slashed-zero;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  .bg-blur,
  .bg-blur-neutral-highlighted,
  .bg-blur-neutral-faded {
    backdrop-filter: blur(6px);
    transition: backdrop-filter 0s;
  }
  .bg-gradient-radial-from-white {
    background: radial-gradient(
      50% 50% at 50% 50%,
      var(--rs-color-background-elevation-base) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  .bg-dotted {
    --dot-canvas-bg: var(--rs-color-background-elevation-base);
    --dot-color: var(--rs-color-border-neutral-faded);
    background: linear-gradient(
          90deg,
          var(--dot-canvas-bg) 10px,
          transparent 1%
        )
        50%,
      linear-gradient(var(--dot-canvas-bg) 10px, transparent 1%) 50%,
      var(--dot-color);
    background-size: 12px 12px;
  }
  .bg-checkered,
  .bg-checkered-faded {
    --checkered-canvas-bg: var(--rs-color-background-elevation-base);
    --checkered-square: var(--rs-color-background-neutral);
    --checkered-square-size: 8px;
    background-image: linear-gradient(
        45deg,
        var(--checkered-square) 25%,
        transparent 25%,
        transparent 75%,
        var(--checkered-square) 75%,
        var(--checkered-square) 100%
      ),
      linear-gradient(
        45deg,
        var(--checkered-square) 25%,
        var(--checkered-canvas-bg) 25%,
        var(--checkered-canvas-bg) 75%,
        var(--checkered-square) 75%,
        var(--checkered-square) 100%
      );
    background-size: var(--checkered-square-size) var(--checkered-square-size);
    background-position: 0 0,
      calc(var(--checkered-square-size) / 2)
        calc(var(--checkered-square-size) / 2);
  }

  .bg-checkered-faded::before {
    transition: background-color 0.2s;
    content: "";
    background-color: var(--rs-color-background-neutral);
    position: absolute;
    inset: 0;
  }

  /* Fixing the cursor for disabled buttons */
  [aria-disabled="true"] {
    cursor: default;
  }

  .docs-container-wrapper {
    min-width: 600px;
    width: 100%;
  }

  .docs-container {
    max-width: var(--content-container-width, 756px);
    box-sizing: border-box;
    overflow: visible;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  /* This needs to be here to override Safari scrollbar color (when system settings is Always on scrollbars */

  [data-rs-color-mode="light"] {
    color-scheme: dark;
  }
}

@layer utilities {
  .text-auto-color span {
    background: inherit;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    opacity: 0.8;
    filter: sepia(5) /* add some color to grey so the rest works */
      saturate(100) /* increase strength of color channels */ invert(1)
      /* invert the color */ grayscale(1) /* make it grey */ contrast(9);
    /* make it black/white */
  }
  /* Extend Tailwind's break-words by word-break: break-word */
  .break-words {
    word-break: break-word;
  }
  .clip-path-token-swatch {
    clip-path: polygon(8px 0, 100% 0, 100% 100%, 0 100%, 0 8px);
  }
  .clip-path-token-swatch-lg {
    clip-path: polygon(32px 0, 100% 0, 100% 100%, 0 100%, 0 32px);
  }
  .clip-right-bottom-corner {
    clip-path: polygon(0 0, 100% 0%, 100% 45%, 45% 100%, 0 100%);
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  /* For Firefox */
  .scrollbar-fancy {
    scrollbar-width: thin;
    scrollbar-color: var(--rs-color-background-neutral-highlighted) transparent;
  }

  .scrollbar-stable {
    scrollbar-gutter: stable;
  }

  /* For WebKit */
  .scrollbar-fancy::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  .scrollbar-fancy::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .scrollbar-fancy::-webkit-scrollbar-thumb {
    background-color: var(--rs-color-background-neutral-highlighted);
    /*border: 2px solid transparent;*/
    border-radius: 100px;
    background-clip: content-box;
  }

  .scrollbar-fancy::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .scrollbar-fancy::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.7);
  }

  /*
       A workaround for Reshaped toasts a tabs override, before we migrate them to Tailwind
       */
  .dm-toast {
    --rs-view-p-vertical-s: 3 !important;
    --rs-view-p-horizontal-s: 3 !important;
    pointer-events: auto;
    z-index: 1050;
  }

  .dm-tabs {
    --rs-font-size-body-3: var(--rs-font-size-body-2);
    --rs-font-weight-body-3: var(--rs-font-weight-medium);
    --rs-unit-radius-medium: var(--rs-unit-radius-small);
    --rs-color-background-neutral-faded: var(--rs-color-background-neutral);
  }

  .dm-input-headless {
    --rs-font-size-body-3: var(--rs-font-size-body-2);
    --rs-font-weight-body-3: var(--rs-font-weight-medium);
  }

  .dm-input-headless > input::placeholder {
    color: var(--rs-color-foreground-neutral-placeholder);
  }
}

.force-disable-transitions * {
  transition: all 0ms !important;
  transition: transform 150ms !important;
  /* All other transitions (transform, translate, etc.) will remain unaffected */
}
